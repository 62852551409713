<template>
  <section>
    <div class="heading secondary--text">
      <h1>About Me</h1>
    </div>
    <div class="secondary" style="min-height: 0.3em; min-width: 0.3em"></div>

    <section class="aboutMe pt-16">
      <v-row class="d-flex justify-center align-center pb-16">
        <v-card width="70em" class="primary darken-4 secondary--text d-flex justify-center">
          <article>
            Currently under construction
          </article>
        </v-card>
      </v-row>
      <div class="d-flex justify-center pb-9">
        <img src="https://i.ibb.co/fQfZPg8/Profile-Pic.jpg" alt="Profile-Pic of Weston Bridson in Colorado" width="500" border="0">
      </div>
      <v-row class="d-flex justify-center align-center py-9">
        <v-card class="primary darken-4 pa-9 secondary--text" width="70em">
          <article>
            Hello! I am Weston Bridson. I work in software development, but really enjoy game development. Development of any sort, is a
            very big time commitment. In order to make sure I have enough time for development in my free time, as well as time to stay fit,
            pray, and keep up with friends, I follow a very strict daily and weekly routine. 
          </article>
        </v-card>
      </v-row>
    </section>
    <section class="contact">
      <div class="heading secondary--text">
        <h1>Get In Touch</h1>
      </div>
      <contact-form></contact-form>
    </section>
  </section>
</template>

<script>
import ContactForm from "../UI/ContactForm.vue"
export default {
  components:{
    ContactForm,
  }
}
</script>

<style scoped>
.heading {
  font-family: pristina;
  font-size: 3.5em;
  text-align: center;
}
article {
  font-size: 1.5em;
  font-family: sitkBanner;
}
</style>
