<template>
  <section class="d-flex justify-center">
    <v-card color="primary darken-4" width="60em" class="mt-5 mb-16">
      <v-form ref="contactForm" class="px-16 py-9">
        <v-row>
          <input type="hidden" name="contact_number" dark />
        </v-row>
        <v-row>
          <v-text-field
            type="text"
            v-model="templateParams.from_name"
            color="secondary"
            placeholder="Name"
            name="from_name"
            :rules="nameRules"
            dark
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field
            type="text"
            v-model="templateParams.from_email"
            color="secondary"
            placeholder="Email"
            name="from_email"
            :rules="emailRules"
            dark
          ></v-text-field>
        </v-row>
        <v-row>
          <v-textarea
            type="text"
            v-model="templateParams.message"
            placeholder="Enter your message here"
            color="secondary"
            name="message"
            :rules="nameRules"
            dark
          ></v-textarea>
        </v-row>
        <v-row justify="center" class="secondary--text">
          <v-btn
            width="15em"
            class="secondary black--text"
            @click="send"
            color="secondary"
            :disabled="isError"
          >
            <v-progress-circular
              indeterminate
              color="black"
              v-if="isLoading"
            ></v-progress-circular>
            <span v-else> Send </span>
          </v-btn>
        </v-row>
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          :color="snackColor"
        >
          {{ snackText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-form>
    </v-card>
  </section>
</template>

<script>
import emailjs from "@emailjs/browser";
import lodash from "lodash";
export default {
  props:{
    projectName: {type: String, default: "General"}
  },  
  data: () => ({
    templateParams: {
      project_name: "",
      to_name: "Weston Bridson",
      to_email: "wbridson97@gmail.com",
      from_name: "",
      from_email: "",
      reply_to: "",
      message: "",
    },
    nameRules: [(v) => (v && v.length > 0) || `Field can not be blank`],
    emailRules: [
      (v) =>
        (v && lodash.includes(v, "@")) || `Please provide an email address`,
    ],
    isLoading: false,
    snackbar: false,
    snackText: "",
    snackColor: "",
    timeout: 3000,
  }),
  computed: {
    isError() {
      if (
        this.templateParams.from_name.length < 1 ||
        this.templateParams.message.length < 1 ||
        !lodash.includes(this.templateParams.from_email, "@")
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async send() {
      this.isLoading = true;
      //userID from:
      //https://dashboard.emailjs.com/admin/integration
      // emailjs.send(serviceID, templateID, templateParams, userID);
      let replyTo = this.templateParams.from_email;
      this.templateParams.reply_to = replyTo;
      this.templateParams.project_name = this.projectName;
      try {
        await emailjs.send(
          "service_q05cmed",
          "contact_form",
          this.templateParams,
          "OcKx9Iglw8i9yiDIc"
        );
      } catch (err) {
        alert(err.message + " \nPlease try again");
        this.isLoading = false;
        this.activateSnackbar("Error occurred", 4000, "red--text");
        console.log(err);
      } finally {
        this.templateParams.from_name = "";
        this.templateParams.from_email = "";
        this.templateParams.message = "";
        await this.$refs.contactForm.resetValidation();
        this.isLoading = false;
        this.activateSnackbar("Message Sent!", 4000, "secondary--text");
      }
      // this.isLoading = false;
    },
    activateSnackbar(text, timeout, color) {
      this.snackText = text;
      this.timeout = timeout;
      this.snackColor = color;
      this.snackbar = true;
    },
  },
};
</script>
